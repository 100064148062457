
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import store from "@/store";
import { ErrorMessage, Field, Form } from "vee-validate";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "broker-building-house-insurance-claims-discharge-voucher",
  components: {
    ErrorMessage,
    Field,
    Form,
    GoBackButton,
  },
  props: ["publicId"],
  data() {
    return {
      // publicId: this.$route.params.publicId,
      claim: {},
      paymentEvidence: "",
      settlementOfferPublicId: "",
      route: "",
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const acceptDischargeVoucher = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();

            formData.append("paymentEvidence", values["paymentEvidence"]);
            formData.append(
              "settlementOfferPublicId",
              values["settlementOfferPublicId"]
            );

            ApiService.setHeader();
            ApiService.post(
              `/settlement/${values["settlementOfferPublicId"]}/payment-evidence`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then(() => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Claim was successfully marked as settled",
                  "success"
                );
                resetForm();
                // Redirect back to clients claims list page
                router.push({
                  name: "building-house-insurance-claims-discharge-voucher",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Discharge Voucher", [
        "Claims",
        "House Insurance Claim",
        "Building",
      ]);
    });

    return { submitButton1, acceptDischargeVoucher, user };
  },
  created() {
    // Get the authenticated user role
    const user = store.getters.currentUser;

    // The the default routes based on the authenticated user role
    this.route =
      user.role == variables.SUPER_ADMIN_USER_ROLE
        ? variables.SUPERADMIN_CLIENT_CLAIMS_ROUTE
        : variables.BROKER_CLIENTS_CLAIMS_ROUTE;

    this.getClaimInformation();

    //Set page title
    document.title =
      "House Insurance Claim: Discharge Voucher | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.route}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to variables
            this.claim = data.data;
            this.settlementOfferPublicId = data.data.settlementOffer.publicId;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    paymentEvidenceSelected(event) {
      this.paymentEvidence = event.target.files[0];
    },
  },
});
